// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/sn-manager/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/sn-manager/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#ScvqNHuthovFr_Sz64Vv {\n    padding: 10px;\n}\n\n#ScvqNHuthovFr_Sz64Vv #MTPOChQ6XYxJLSMOXI_q {\n    border: 1px solid #dee2e6;\n    border-top: 0px;\n    padding: 1em;\n}\n\n#ScvqNHuthovFr_Sz64Vv #MTPOChQ6XYxJLSMOXI_q table {\n    width: 100%;\n}\n\n#ScvqNHuthovFr_Sz64Vv #MTPOChQ6XYxJLSMOXI_q table td {\n    border: 1px solid #dee2e6;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#main {\n    padding: 10px;\n}\n\n#main #content {\n    border: 1px solid #dee2e6;\n    border-top: 0px;\n    padding: 1em;\n}\n\n#main #content table {\n    width: 100%;\n}\n\n#main #content table td {\n    border: 1px solid #dee2e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ScvqNHuthovFr_Sz64Vv",
	"content": "MTPOChQ6XYxJLSMOXI_q"
};
export default ___CSS_LOADER_EXPORT___;
