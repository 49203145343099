import * as React from 'react';
import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import * as lot from '../../data/lot';
import * as api from '../api';

type lot = lot.lot & {size: number};

export default function ({lots, session}: {
    lots: lot[],
    session: session,
}) {
    const [deleting, setDeleting] = useState(false);
    const [details, setDetails] = useState<string[] | null>(null);

    return (
    <>
        <table>
            <thead>
                <tr>
                    <td>建立時間</td>
                    <td>備註</td>
                    <td>序號數量</td>
                </tr>
            </thead>
            <tbody>
                {(() => {
                    return lots.map(lot => (
                    <tr>
                        <td>{lot.ctime.toLocaleString()}</td>
                        <td>{lot.memo}</td>
                        <td>
                            {lot.size}&nbsp;&nbsp;
                            <button onClick={e => api.lot.download(lot.project, lot.ctime, session)}>下載</button>
                            <button onClick={async _ => {
                                const sns = await api.lot.get(lot.project, lot.ctime, session);
                                setDetails(sns);
                            }}>顯示</button>
                            <button onClick={async e => {
                                const ok = confirm('確定刪除?');
                                if (ok) {
                                    setDeleting(true);
                                    await api.lot.remove(lot.project, lot.ctime, session);
                                    document.location.reload();
                                }
                            }} disabled={deleting}>刪除</button>
                        </td>
                    </tr>));
                })()}
            </tbody>
        </table>
        <Display show={details !== null} onHide={() => setDetails(null)} sns={details}/>
    </>);
}

function Display({show, onHide, sns}: {
    show: boolean,
    onHide: () => void,
    sns: string[] | null,
}) {
    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>序號</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
            <textarea style={{height: '300px'}}>
                {(sns || []).join('\n')}
            </textarea>
        </Modal.Body>
      
        <Modal.Footer>
            <button onClick={onHide}>關閉</button>
        </Modal.Footer>
    </Modal>);
}

type session = {
    user: {
        email: string,
        name: string,
    },
    data: string,
    signature: string,
}
