import * as React from 'react';
import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Nav from 'react-bootstrap/Nav';
import LotMaker from './index/lot-maker';
import Lot from './index/lot';
import * as lot from '../data/lot';
import * as proj from '../data/project';
import * as api from './api';

const css = require('./index.css').default;

type lot = lot.lot & {size: number};

function Root() {
    const [session, setSession] = useState<session | null>(null);
    const [projects, setProjects] = useState<proj.project[]>([]);
    const [selectedProject, setSelectedProject] = useState<string | null>(null);
    const [lots, setLots] = useState<lot[]>([]);

    useEffect(() => {
        // 載入 user
        const user = localStorage.getItem('user');
        if (user === null) {
            const cookie = getCookie('user');
            if (cookie === null) {
                const params = new URLSearchParams();
                const url = new URL(document.location.href);
                params.append('loginUrl', `${url.protocol}//${url.host}/login`);
                params.append('from', `${url.protocol}//${url.host}/`);
                document.location.href = `https://sign-in.king-an.com.tw/?${params.toString()}`;
            } else {
                localStorage.setItem('user', b64DecodeUnicode(cookie));
                setSession(JSON.parse(b64DecodeUnicode(cookie)));
            }
        } else {
            setSession(JSON.parse(user));
        }
    }, []);

    useEffect(() => {
        if (session) {
            api.project.list(session.user.email)
            .then(projs => {
                setProjects(projs);
                if (projs.length > 0) {
                    setSelectedProject(projs[0].id);
                }
            });
        }
    }, [JSON.stringify(session)]);

    useEffect(() => {
        if (selectedProject) {
            api.lot.list(selectedProject)
            .then(setLots);
        }
    }, [selectedProject]);

    if (selectedProject) {
        return (
        <div id={css.main}>
            <Nav variant="tabs" defaultActiveKey={selectedProject} onSelect={setSelectedProject}>
                {(() => {
                    return projects.map(proj => <Nav.Item><Nav.Link eventKey={proj.id}>{proj.name}</Nav.Link></Nav.Item>);
                })()}
            </Nav>
            <div id={css.content}>
                <Lot lots={lots} session={session!}/>
                <hr/>
                <h3>建立新一批序號</h3>
                <LotMaker
                    project={selectedProject}
                    session={session!}
                    onDone={() => {
                        api.lot.list(selectedProject).then(setLots);
                    }}
                />
            </div>
        </div>);
    } else {
        return <></>;
    }
}

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
           c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

function b64DecodeUnicode(str: string) {
    // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

type session = {
    user: {
        email: string,
        name: string,
    },
    data: string,
    signature: string,
}

const root = createRoot(document.getElementById('root')!);
root.render(<Root/>);
