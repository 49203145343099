import * as Lot from '../data/lot';
import * as Proj from '../data/project';

const base = window.location.host.includes('localhost') ? 'http://localhost:3000/api' : '/api';

export namespace project {
    export async function list(user: string): Promise<Proj.project[]> {
        const params = new URLSearchParams();
        params.append('user', user);
        return await (await fetch(`${base}/project?${params.toString()}`)).json();
    }
}

export namespace lot {
    export async function make(
        project: string,
        length: number,
        splitEvery: number | null,
        count: number,
        memo: string | null,
        session: session,
    ): Promise<number> {
        return (await fetch(`${base}/project/${project}/lot`, {
            method: 'post',
            body: JSON.stringify({length, splitEvery, count, memo}),
            headers: {
                'Content-Type': 'application/json',
                session: encodeURIComponent(JSON.stringify(session)),
            },
        })).json();
    }

    export async function list(project: string): Promise<(Lot.lot & {size: number})[]> {
        type res = {
            project: string,
            ctime: string,
            size: number,
            memo: string | null,
            validTo: string | null,
            validFrom: string | null,
        }
        const lots = (await fetch(`${base}/project/${project}/lot`)).json() as Promise<res[]>;
        return (await lots).map(lot => ({
            ...lot,
            ctime: new Date(lot.ctime),
            validTo: lot.validTo ? new Date(Date.parse(lot.validTo)) : null,
            validFrom: lot.validFrom ? new Date(Date.parse(lot.validFrom)) : null,
        }));
    }

    export async function get(project: string, lot: Date, session: session): Promise<string[]> {
        return (await fetch(`${base}/project/${project}/lot/${lot.toISOString()}`, {
            headers: {
                session: encodeURIComponent(JSON.stringify(session)),
            },
        })).json();
    }

    export async function download(project: string, lot: Date, session: session) {
        const blob = await (await fetch(`${base}/project/${project}/lot/${lot.toISOString()}/download`, {
            headers: {
                session: encodeURIComponent(JSON.stringify(session)),
            },
        })).blob();
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
    }

    export async function remove(project: string, lot: Date, session: session) {
        await (await fetch(`${base}/project/${project}/lot/${lot.toISOString()}`, {
            method: 'delete',
            headers: {
                session: encodeURIComponent(JSON.stringify(session)),
            },
        })).json();
    }
}

type session = {
    user: {
        email: string,
    },
    data: string,
    signature: string,
};
