import * as React from 'react';
import {useState} from 'react';
import * as api from '../api';

export default function ({project, session, onDone}: {
    project: string,
    session: session,
    onDone: () => void,
}) {
    const [cnt, setCnt] = useState(0);
    const [memo, setMemo] = useState('');
    const [making, setMaking] = useState(false);

    return (
    <table>
        <tr>
            <td>序號數量</td>
            <td><input type='number' name='count' onChange={e => setCnt(parseInt(e.target.value))}/></td>
        </tr>
        <tr>
            <td>說明</td>
            <td><input type='text' name='memo' onChange={e => setMemo(e.target.value)}/></td>
        </tr>
        <tr>
            <td colSpan={2}>
                <button onClick={async e => {
                    setMaking(true);
                    await api.lot.make(project, 12, 4, cnt, memo, session);
                    onDone();
                    setMaking(false);
                }} disabled={making}>{making ? '建立中' : '建立'}</button>
            </td>
        </tr>
    </table>);
}

type session = {
    user: {
        email: string,
        name: string,
    },
    data: string,
    signature: string,
}
